import React from 'react'
import { useParams } from 'react-router-dom'
import MyProfile from '../other/userProfile/MyProfile'

export default function ViewAccount() {
    const getParams = useParams();    
    return (
        <React.Fragment>
            <MyProfile userId={getParams.id} />
        </React.Fragment>
    )
}
