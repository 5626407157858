export const progressAssessment = [
    {
        "name": "Progress",
        "key": "progress",
        "for": "normal",
        "type": "radio",
        "same_name": "progress",
        'collapse': true,
        "child": [
            [
                {
                    "name": "improved",
                    "key": "improved",
                    "for": "normal",
                    "type": "checkbox",
                    "child": [
                        [
                            {
                                "name": "Mood",
                                "key": "mood",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Affect",
                                "key": "affect",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Relationships",
                                "key": "relationships",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Coping with adjustment",
                                "key": "copingWithAdjustment",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Emotional regulation",
                                "key": "emotionalRegulation",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Interaction skills",
                                "key": "interactionSkills",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Self esteem",
                                "key": "selfEsteem",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "ADLs",
                                "key": "ADLs",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "add new",
                                "key": "add-new",
                                "type": "checkbox",
                                "for": "addnew"
                            }
                        ]
                    ]
                }
            ],
            [
                {
                    "name": "reduction in",
                    "key": "reductionIn",
                    "for": "normal",
                    "type": "checkbox",
                    "child": [
                        [
                            {
                                "name": "self-harm",
                                "key": "selfHarm",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Aggression",
                                "key": "aggression",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Panic attacks",
                                "key": "panicAttacks",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Dissociative features",
                                "key": "dissociativeFeatures",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "isolation",
                                "key": "isolation",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Behavioral issues",
                                "key": "behavioralIssues",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Suicidal ideations",
                                "key": "suicidalIdeations",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "add new",
                                "key": "add-new",
                                "type": "checkbox",
                                "for": "addnew"
                            }
                        ]
                    ]
                }
            ],[
                {
                    "notes": "",
                    "notesText": [
                        {
                            "text": "hello",
                            "show_text": false
                        }
                    ]
                }
            ]
        ]
    },
    {
        "name": "Neither improved or regressed (no change)",
        "key": "neitherImprovedOrRegressed",
        "for": "normal",
        "type": "radio",
        "same_name": "progress",
        'collapse': true,
        "child": [
            [
                {
                    "name": "Mood",
                    "key": "mood",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Affect",
                    "key": "affect",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Relationships",
                    "key": "relationships",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Coping with adjustment",
                    "key": "copingWithAdjustment",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Emotional regulation",
                    "key": "emotionalRegulation",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Interaction skills",
                    "key": "interactionSkills",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Self esteem",
                    "key": "selfEsteem",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Self-harm",
                    "key": "selfHarm",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Aggression",
                    "key": "aggression",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Panic attacks",
                    "key": "panicAttacks",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Dissociative features",
                    "key": "dissociativeFeatures",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Isolation",
                    "key": "isolation",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "Behavioral issues",
                    "key": "behavioralIssues",
                    "for": "normal",
                    "type": "checkbox"
                },
                {
                    "name": "add new",
                    "key": "add-new",
                    "type": "checkbox",
                    "for": "addnew"
                }
            ], [
                {
                    "notes": "",
                    "notesText": [
                        {
                            "text": "hello",
                            "show_text": false
                        }
                    ]
                }
            ]
        ]
    },
    {
        "name": "Regression",
        "key": "regression",
        "for": "normal",
        "type": "radio",
        "same_name": "progress",
        'collapse': true,
        "child": [
            [
                {
                    "name": "Decreased",
                    "key": "decreased",
                    "for": "normal",
                    "type": "checkbox",
                    "child": [
                        [
                            {
                                "name": "Mood",
                                "key": "mood",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Quality of affect",
                                "key": "qualityOfAffect",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Quality of relationships",
                                "key": "qualityOfRelationships",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Coping with adjustment",
                                "key": "copingWithAdjustment",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Emotional regulation skills",
                                "key": "emotionalRegulationSkills",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Interaction skills",
                                "key": "interactionSkills",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Self-esteem",
                                "key": "selfEsteem",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "add new",
                                "key": "add-new",
                                "type": "checkbox",
                                "for": "addnew"
                            }
                        ]
                    ]
                }
            ],
            [
                {
                    "name": "Increased",
                    "key": "increased",
                    "for": "normal",
                    "type": "checkbox",
                    "child": [
                        [
                            {
                                "name": "Self-harm",
                                "key": "selfHarm",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Aggression",
                                "key": "aggression",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Panic attacks",
                                "key": "panicAttacks",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Dissociative features",
                                "key": "dissociativeFeatures",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Isolation",
                                "key": "isolation",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Behavioral issues",
                                "key": "behavioralIssues",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "Suicidal ideations",
                                "key": "suicidalIdeations",
                                "for": "normal",
                                "type": "checkbox"
                            },
                            {
                                "name": "add new",
                                "key": "add-new",
                                "type": "checkbox",
                                "for": "addnew"
                            }
                        ]
                    ]
                }
            ], [
                {
                    "notes": "",
                    "notesText": [
                        {
                            "text": "hello",
                            "show_text": false
                        }
                    ]
                }
            ]
        ]
    },        
]

export const recomendation = {
  name: "Recommendations/Plan",
  key: "recommendationsPlan",
  child: [
    {
      name: "Changing",
      key: "changing",
      for: "normal",
      type: "checkbox",
      child: [
        [
          {
            name: "Counseling duration and frequency",
            key: "counselingDurationFrequency",
            for: "normal",
            type: "checkbox",
            child: [
              [
                {
                  name: "Increasing",
                  key: "increasing",
                  for: "normal",
                  type: "checkbox",
                  child: [
                    [
                      {
                        name: "Session length",
                        key: "sessionLength",
                        for: "normal",
                        type: "checkbox",
                      },
                      {
                        name: "Session frequency",
                        key: "sessionFrequency",
                        for: "normal",
                        type: "checkbox",
                      },
                    ],
                  ],
                },
                {
                  name: "Decreasing",
                  key: "decreasing",
                  for: "normal",
                  type: "checkbox",
                  child: [
                    [
                      {
                        name: "Session length",
                        key: "sessionLength",
                        for: "normal",
                        type: "checkbox",
                      },
                      {
                        name: "Session frequency",
                        key: "sessionFrequency",
                        for: "normal",
                        type: "checkbox",
                      },
                    ],
                  ],
                },
              ],
            ],
          },
          {
            name: "add new",
            key: "add-new",
            type: "checkbox",
            for: "addnew",
          },
        ],
      ],
    },
    {
      name: "Continuing",
      key: "continuing",
      for: "normal",
      type: "checkbox",
      child: [
        [
          {
            name: "Counseling duration and frequency",
            key: "counselingDurationFrequency",
            for: "normal",
            type: "checkbox",
          },
          {
            name: "Medication management",
            key: "medicationManagement",
            for: "normal",
            type: "checkbox",
          },
          {
            name: "Working towards treatment goals",
            key: "workingTowardsTreatmentGoals",
            for: "normal",
            type: "checkbox",
          },
          {
            name: "add new",
            key: "add-new",
            type: "checkbox",
            for: "addnew",
          },
        ],
      ],
    },
    {
      name: "Discharge",
      key: "discharge",
      for: "normal",
      type: "checkbox",
      notes: "true",
      notesText: [
        {
          text: "hello",
          show_text: false,
        },
      ],
      show_notes: true,
    },
    {
      name: "Making an appointment",
      key: "makingAppointment",
      for: "normal",
      type: "checkbox",
      child: [
        [
          {
            name: "Psychiatrist",
            key: "psychiatrist",
            for: "normal",
            type: "checkbox",
          },
          {
            name: "Primary doctor",
            key: "primaryDoctor",
            for: "normal",
            type: "checkbox",
          },
          {
            name: "add new",
            key: "add-new",
            type: "checkbox",
            for: "addnew",
          },
        ],
      ],
    },
    {
      name: "Updating",
      key: "updating",
      for: "normal",
      type: "checkbox",
      child: [
        [
          {
            name: "Treatment plan",
            key: "treatmentPlan",
            for: "normal",
            type: "checkbox",
            child: [
              [
                {
                  name: "To address new issues",
                  key: "toAddressNewIssues",
                  for: "normal",
                  type: "checkbox",
                },
                {
                  name: "to address changes in client and their progress",
                  key: "toAddressChangesTheirProgress",
                  for: "normal",
                  type: "checkbox",
                },
                {
                  name: "add new",
                  key: "add-new",
                  type: "checkbox",
                  for: "addnew",
                },
              ],
            ],
          },
          {
            name: "add new",
            key: "add-new",
            type: "checkbox",
            for: "addnew",
          },
        ],
      ],
    },

    {
      name: "note",
      key: "note",
      for: "inline-notes",
      textArea: true,
      type: "checkbox",
      placeholder: "Add your notes here",
    },
  ],
};