import FrontAbout from '../Frontend/FrontAbout';
import FrontHowItWorks from '../Frontend/FrontHowItWorks';
import FrontPlan from '../Frontend/FrontPlan';
import FrontContactUs from '../Frontend/FrontContactUs';
import FrontHeader from './FrontHeader';
import FrontFooter from './FrontFooter';
import FrontMainImage from './FrontMainImage';

function ComingSoon() {
    return (
        <div class="d-flex flex-column flex-root font-fira-sans">
            <FrontHeader>
                <FrontMainImage />
            </FrontHeader>
            <FrontAbout />
            <FrontHowItWorks />
            <FrontPlan />
            <FrontContactUs />
            <FrontFooter />
        </div>
    );
}

export default ComingSoon;