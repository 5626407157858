// import ReactPlayer from "react-player"
import intro from '../assets/video/Introducing_NoteNest.mp4'

function HowItWorks() {
    return (
        <div className="mb-n10 mb-lg-n20 z-index-2">
            {/*begin::Container*/}
            <div className="container">
                {/*begin::Heading*/}
                <div className="text-center mb-17">
                    {/*begin::Title*/}
                    <h3 className="fs-2hx text-dark mb-5" id="how-it-works" data-kt-scroll-offset="{default: 100, lg: 150}">How does it work? </h3>
                    {/*end::Title*/}
                    {/*begin::Text*/}
                    <div className="fs-5 text-muted">Once you have an account, creating documentation is as easy as 3 steps...</div>
                    {/*end::Text*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Row*/}
                <div className="row w-100 gy-10 mb-md-20 howItWork">
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5 wow fadeInLeft animated">
                        {/*begin::Story*/}
                        <div className="text-center mb-10 mb-md-0">
                            {/*begin::Illustration*/}
                            <img src="assets/media/img/image-03.jpg" className="mb-9" alt="Add client" />
                            {/*end::Illustration*/}
                            {/*begin::Heading*/}
                            <div className="d-flex flex-center mb-5">
                                {/*begin::Badge*/}
                                <span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">1</span>
                                {/*end::Badge*/}
                                {/*begin::Title*/}
                                <div className="fs-5 fs-lg-3 fw-bolder text-dark">Add Client</div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Description*/}
                            <div className="fs-6 fs-lg-4 text-muted">Input some basic client information
                                like preferred name, gender, and DOB and select that client.</div>
                            {/*end::Description*/}
                        </div>
                        {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5 wow fadeInDown animated">
                        {/*begin::Story*/}
                        <div className="text-center mb-10 mb-md-0">
                            {/*begin::Illustration*/}
                            <img src="assets/media/img/image-04.jpg" className="mb-9" alt="Select Keyword" />
                            {/*end::Illustration*/}
                            {/*begin::Heading*/}
                            <div className="d-flex flex-center mb-5">
                                {/*begin::Badge*/}
                                <span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">2</span>
                                {/*end::Badge*/}
                                {/*begin::Title*/}
                                <div className="fs-5 fs-lg-3 fw-bolder text-dark">Select Keywords</div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Description*/}
                            <div className="fs-6 fs-lg-4 text-muted">Select the type of document you would like
                                to create and start clicking keywords. As you click, watch the document write itself in seconds.</div>
                            {/*end::Description*/}
                        </div>
                        {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-4 px-5 wow fadeInRight animated">
                        {/*begin::Story*/}
                        <div className="text-center mb-10 mb-md-0">
                            {/*begin::Illustration*/}
                            <img src="assets/media/img/image-05.jpg" className="mb-9" alt="Review Document" />
                            {/*end::Illustration*/}
                            {/*begin::Heading*/}
                            <div className="d-flex flex-center mb-5">
                                {/*begin::Badge*/}
                                <span className="badge badge-circle badge-light-success fw-bolder p-5 me-3 fs-3">3</span>
                                {/*end::Badge*/}
                                {/*begin::Title*/}
                                <div className="fs-5 fs-lg-3 fw-bolder text-dark">Review Your Document</div>
                                {/*end::Title*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Description*/}
                            <div className="fs-6 fs-lg-4 text-muted">Read over the document and make any desired changes or additions, save it to the system, and you are done!</div>
                            {/*end::Description*/}
                        </div>
                        {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                </div>
                {/*end::Row*/}
                {/*begin::Product slider*/}
                <div className="tns tns-default">
                <div
          className='homepage-videos'
            style={{
              position: "relative",
              // width: "100%",
            //   height: 0,
              // "padding-top": "56.2500%",
              "padding-bottom": 0,
            //   "box-shadow": "0 2px 8px 0 rgba(63,69,81,0.16)",
              "margin-top": "1.6em",
              "margin-bottom": "0.9em",
              overflow: "hidden",
            //   "border-radius": "8px",
              "will-change": "transform",
            }}
          >
                <iframe width="100%" height="500px" src="https://www.youtube.com/embed/lH-iHyzRSoo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                style={{
                    // position: "absolute",
                    // width: "100%",
                    // height: "100%",
                    // top: '50%',
                    // left: '50%',
                    // top: 0,
                    // left: '50%',
                    border: "none",
                    "border-radius": "8px",
                    padding: 0,
                    margin: 'auto',
                    // transform: 'translate(-50%,-50%)'
                  }}
                ></iframe>
                </div>
                {/* <div
          className='homepage-videos'
            style={{
              position: "relative",
              // width: "100%",
            //   height: 0,
              // "padding-top": "56.2500%",
              "padding-bottom": 0,
            //   "box-shadow": "0 2px 8px 0 rgba(63,69,81,0.16)",
              "margin-top": "1.6em",
              "margin-bottom": "0.9em",
              overflow: "hidden",
            //   "border-radius": "8px",
              "will-change": "transform",
            }}
          >
              <video style={{
                // position: "absolute",
                // width: "100%",
                // height: "100%",
                // top: '50%',
                // left: '50%',
                // top: 0,
                // left: '50%',
                border: "none",
                "border-radius": "8px",
                padding: 0,
                margin: 'auto',
                // transform: 'translate(-50%,-50%)'
              }} controls>
            <source src={intro} type="video/mp4" />
</video>
          </div> */}
                    {/*begin::Slider*/}
                </div>
                {/*end::Product slider*/}
            </div>
            {/*end::Container*/}
        </div>
    )
}

export default HowItWorks