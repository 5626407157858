export const dischargeSummaryCommonChild_0 = [
    {
        "name": "significant progress",
        "key": "significantProgress",
        "type": "radio",
        "for": "normal"
    },
    {
        "name": "moderate progress",
        "key": "moderateProgress",
        "type": "radio",
        "for": "normal"
    },
    {
        "name": "minimal progress",
        "key": "minimalProgress",
        "type": "radio",
        "for": "normal"
    }
]

export const dischargeSummaryCommonChild_1 = [
    [
        {
            "name": "on",
            "key": "on",
            "for": "inline-notes",
            "same_name": "fromOnAround",
            "with_checkbox": true,
            "type": "radio",
            "start_text": "on"
        },
        {
            "name": "outOf",
            "key": "outOf",
            "for": "inline-notes",
            "type": "text",
            "start_text": "out of",
            "end_text": "occasions"
        }
    ],
    [
        {
            "name": "times",
            "key": "times",
            "for": "inline-notes",
            "same_name": "fromOnAround",
            "with_checkbox": true,
            "type": "radio",
            "end_text": "times",
            "child": [
                [
                    {
                        "name": "daily",
                        "key": "daily",
                        "type": "radio",
                        "for": "normal"
                    },
                    {
                        "name": "weekly",
                        "key": "weekly",
                        "type": "radio",
                        "for": "normal"
                    },
                    {
                        "name": "monthly",
                        "key": "monthly",
                        "type": "radio",
                        "for": "normal"
                    },
                    {
                        "name": "throughout services",
                        "key": "throughoutServices",
                        "type": "radio",
                        "for": "normal"
                    },
                    {
                        "name": "add new",
                        "key": "add-new",
                        "type": "radio",
                        "for": "addnew"
                    }
                ]
            ]
        }
    ]
]

export const dischargeSummeryDidWellWith = {
    "name": "did well with",
    "key": "didWellWith",
    "type": "checkbox",
    "for": "normal",
    "child": [
        [
            {
                "name": "art therapy",
                "key": "artTherapy",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "anger management techniques",
                "key": "angerManagementTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "boundary setting",
                "key": "boundarySetting",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "CBT",
                "key": "CBT",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "emotional expression skills",
                "key": "emotionalExpressionSkills",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "communication skills",
                "key": "communicationSkills",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of childhood",
                "key": "explorationOfChildhood",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of connections between emotions and behaviours",
                "key": "explorationOfConnectionsBetweenEmotionsAndBehaviours",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of relationships",
                "key": "explorationOfRelationships",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of trauma",
                "key": "explorationOfTrauma",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of triggers",
                "key": "explorationOfTriggers",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "family dynamics work",
                "key": "familyDynamicsWork",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "gestalt Therapy Techniques",
                "key": "gestaltTherapyTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "interaction skills",
                "key": "interactionSkills",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "mindfulness",
                "key": "mindfulness",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "motivational interviewing",
                "key": "motivationalInterviewing",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "person-centered therapy",
                "key": "personcenteredTherapy",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "play therapy",
                "key": "playTherapy",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "psychodynamic techniques",
                "key": "psychodynamicTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "relaxation techniques",
                "key": "relaxationTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "reality therapy techniques",
                "key": "realityTherapyTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "role playing",
                "key": "rolePlaying",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "skills to improve client's self-awareness",
                "key": "skillsToImproveClientsSelfAwareness",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "add new",
                "key": "add-new",
                "type": "checkbox",
                "for": "addnew"
            }
        ]
    ]
}

export const dischargeSummeryStruggledWith = {
    "name": "struggled with",
    "key": "struggledWith",
    "type": "checkbox",
    "for": "normal",
    "child": [
        [
            {
                "name": "art therapy",
                "key": "artTherapy",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "anger management techniques",
                "key": "angerManagementTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "boundary setting",
                "key": "boundarySetting",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "CBT",
                "key": "CBT",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "emotional expression skills",
                "key": "emotionalExpressionSkills",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "communication skills",
                "key": "communicationSkills",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of childhood",
                "key": "explorationOfChildhood",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of connections between emotions and behaviours",
                "key": "explorationOfConnectionsBetweenEmotionsAndBehaviours",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of relationships",
                "key": "explorationOfRelationships",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of trauma",
                "key": "explorationOfTrauma",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "exploration of triggers",
                "key": "explorationOfTriggers",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "family dynamics work",
                "key": "familyDynamicsWork",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "gestalt therapy techniques",
                "key": "gestaltTherapyTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "interaction skills",
                "key": "interactionSkills",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "mindfulness",
                "key": "mindfulness",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "motivational interviewing",
                "key": "motivationalInterviewing",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "person-centered therapy",
                "key": "personcenteredTherapy",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "play therapy",
                "key": "playTherapy",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "psychodynamic techniques",
                "key": "psychodynamicTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "relaxation techniques",
                "key": "relaxationTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "reality therapy techniques",
                "key": "realityTherapyTechniques",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "role playing",
                "key": "rolePlaying",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "skills to improve client's self-awareness",
                "key": "skillsToImproveClientsSelfAwareness",
                "type": "checkbox",
                "for": "normal"
            },
            {
                "name": "add new",
                "key": "add-new",
                "type": "checkbox",
                "for": "addnew"
            }
        ]
    ]
}