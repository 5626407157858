import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../Frontend/Header';
import Footer from '../../../Frontend/Footer';

export default function BusinessAgreement(props) {
    return (
        <>
        <Header />

            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ backgroundImage: 'url(/metronic8/demo2/assets/media/illustrations/sigma-1/14.png' }}>
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <div className="container card">  
                        <div className='my-2'>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', textAlign: 'center', background: '#F1F9FF' }}><strong><span style={{ fontSize: '44px', fontFamily: 'Poppins', color: '#04304C' }}>Privacy Policy</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>1. Our approach to privacy</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>1.1 NoteNest, LLC (“<strong>NoteNest</strong>“, “<strong>we</strong>“, “<strong>our</strong>“, or “<strong>us</strong>“) is committed to protecting your privacy. This privacy policy sets out how we collect, store, process, transfer, share and use data that identifies or is associated with you (“<strong>personal information</strong>“) and information regarding our use of cookies and similar technologies.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>1.2 NoteNest operates a cloud-based note-taking, patient charting solution for mental health professionals available via our websites (our “<strong>Websites</strong>”) including at NoteNest.com and mobile application as well as other products and services that we make available (the “<strong>NoteNest Service</strong>”).</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>1.3 This privacy policy applies to the NoteNest Service.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>1.4 Before accessing or using the NoteNest Service, please ensure that you have read and understood our collection, storage, use and disclosure of your personal information as described in this privacy policy. By accessing or using the NoteNest Service, you are accepting and consenting to the practices described in this privacy policy.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>2. Personal information we collect about you and how we use it</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>2.1 <strong>Information that you or others supply</strong>. We collect personal information about you when you voluntarily submit information directly to us by filling in forms on our Website or by corresponding with us by phone, email or other means. &nbsp;We may also receive personal information about you from individuals or corporate entities which are subscribers to the NoteNest Service (“<strong>Subscribers</strong>“) where you are to be designated a user of the NoteNest Service.&nbsp;</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>Personal information collected may include:&nbsp;</span></p>
                            <ul style={{ listStyleType: 'disc' }}>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>First and last name</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Educational or licensing credentials, including dates associated with such credentials</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Professional license or billing numbers</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Mailing address</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Email address</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Phone number (s)</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Payment information including debit or credit card numbers used for payment</span></li>
                            </ul>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>2.2 <strong>Automatically collected information.&nbsp;</strong>We also automatically collect personal information about you indirectly about how you access and use the NoteNest Service and information about the device you use to access the NoteNest Service. &nbsp;This information can include: &nbsp;your IP address, browser type, domain names, access times and referring website addresses. &nbsp;This information is used for the operation of the service, and to provide general statistics regarding use of the NoteNest Service.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>2.3 <strong>Linking and combining personal information.&nbsp;</strong>We may link or combine the personal information we collect and/or receive about you and the information we collect automatically. This allows us to provide you with a personalized experience regardless of how you interact with us.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>2.4 <strong>Anonymization and aggregation.&nbsp;</strong>We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the NoteNest Service and developing new products and features. We may also share such anonymized information with others.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>3. Disclosure of your personal information</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>3.1 We may share your personal information with any member of our company, which includes our subsidiaries. We will not share your personal information with any third parties except as described in this privacy policy or in connection with the Service. We may share your information with selected third parties, including:</span></p>
                            <ul style={{ marginBottom: '0cm' }} type="disc">
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>Business, vendors, suppliers and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us). &nbsp;Information provided may include:&nbsp;</span></li>
                            </ul>
                            <ol style={{ listStyleType: 'circle' }}>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>First and last name</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Educational or licensing credentials, including dates associated with such credentials</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Professional license or billing numbers</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Mailing address</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Email address</span></li>
                                <li><span style={{ fontFamily: '"Source Sans Pro","sans-serif"', fontSize: '12.0pt', color: '#404040' }}>Phone number (s)</span></li>
                            </ol>
                            <ul style={{ marginBottom: '0cm' }} type="disc">
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>Analytics and search engine providers that assist us in the improvement and optimization of our Website. Information provided may include:&nbsp;</span>
                                    <ul style={{ marginBottom: '0cm' }} type="circle">
                                        <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>IP address, browser type, domain names, access times and referring website addresses.</span></li>
                                    </ul>
                                </li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>Payment processors for the purpose of fulfilling relevant payment transactions. Information provided may include: Name, address, email address, credit card or other payment information;</span></li>
                            </ul>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>We will take steps to ensure that any third parties that receive your personal information comply fully with applicable privacy laws and regulations related to your personal information.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>3.2 In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>We may also disclose personal information if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our agreements with you; or to protect the rights, property, or safety of NoteNest, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>3.3 <strong>Testimonials</strong>. With your consent, we may display your personal testimonials along with other satisfied customers on our site. If you wish to update or delete your testimonial, you can contact us at&nbsp;[]</span><a href="mailto:privacy@NoteNest.com"><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>privacy@NoteNest.com</span></a><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>3.5 <strong>Business Transactions.&nbsp;</strong>We may disclose personal information to a third party if such third party is receiving the information as part of a merger, acquisition, bankruptcy or other transaction. You will be notified of any such mergers or changes in control of ownership. &nbsp;</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>4. Marketing and advertising</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>4.1 From time to time we may contact you with relevant information about the NoteNest Service and our other products and services. Most messages will be sent electronically. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>4.2 If you do not want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. You can also change your marketing preferences at any time by following the unsubscribe link at the bottom of our emails.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>5. Storing and transferring your personal information</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>5.1 <strong>Security</strong>. NoteNest has implemented administrative, technical, and physical safeguards to protect its and its customers information. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>5.2 <strong>Transmission.&nbsp;</strong>While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information in accordance with all applicable laws. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>6. Retaining your information</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>6.1 We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>6.2 To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>7. Your rights in respect of your personal information</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>7.1 In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:</span></p>
                            <ul style={{ marginBottom: '0cm' }} type="disc">
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>The right to access personal data and obtain a copy of personal data in a readily usable format</span></li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>The right to correct inaccuracies in personal data, taking into account the nature of the data and the purposes for which we are using the data</span></li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>The right to opt out of any targeted advertising, sale of personal information and analysis of data which would relate to economic situation, health, personal preferences, interests, reliability, behavior, location or movements</span></li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>The right to equal services and prices regardless of whether you choose to exercise your rights</span></li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>The right to appeal any decision relating to the aforementioned rights</span></li>
                            </ul>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>7.2 If you wish to exercise one of these rights, including an appeal, please contact us using the contact details at the end of this privacy policy or completing a form at this link ___________. Upon request, we will provide you with information about whether we hold any of your personal information. You may access, correct or request deletion of your personal information by logging into your account, or by contacting us at&nbsp;</span><a href="mailto:privacy@clio.com"><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#0069D1' }}>privacy@NoteNest.com</span></a><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>. We will respond to your request within 30 days.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>7.3 NoteNest will not sell your personal information. &nbsp;</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>8. Cookies and similar technologies</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>8.1 Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you from other users of our Service. This helps us to provide you with good service, to enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes.&nbsp;</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>8.2 We use the following types of cookies:</span></p>
                            <ul style={{ marginBottom: '0cm' }} type="disc">
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><strong><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>Strictly necessary cookies</span></strong><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use.</span></li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><strong><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>Analytical/performance cookies</span></strong><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily.</span></li>
                                <li style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"', color: '#404040' }}><strong><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>Functionality cookies</span></strong><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</span></li>
                            </ul>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>8.3 You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>8.4 &nbsp;Our Website may include social media features, such as Facebook Like button and widgets such as the Share button. These features may collect your IP address, which page you are visiting on our Website, and may set a cookie to enable the feature to function properly. Social media features and widgets are hosted on our site or by a third party, and your interactions with these features are governed by the privacy policy of the company providing it.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>9. Our policy towards children</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>9.1 The NoteNest Service is not directed at persons under 18 and we do not intend to collect personal information from children under 18. &nbsp;If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>10. Changes to this policy</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>10.1 We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the “last modified” date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>11. Notice to you</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>11.1 If we need to provide you with information about something, whether for legal, marketing or other business related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><strong><span style={{ fontSize: '21px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#04304C' }}>12. Contacting us</span></strong></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>12.1 Regardless of your location, any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to:</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>NoteNest, LLC</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>8330 Scottingham Drive</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>North Chesterfield, VA 23236</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>Attention: &nbsp;Chloe Arditi, Owner</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>[Email: &nbsp;</span><a href="mailto:chloearditi@gmail.com"><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"' }}>chloearditi@gmail.com</span></a><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>]</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>&nbsp;</span></p>
                            <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.0001pt', marginLeft: '0cm', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri","sans-serif"' }}><span style={{ fontSize: '16px', fontFamily: '"Source Sans Pro","sans-serif"', color: '#404040' }}>[Link to form on website]</span></p>
                        </div>
                    </div>
                </div>
            </div>

        <Footer />
        </>
    )
}
