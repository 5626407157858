import React, { useContext, useEffect, useState } from "react";
import CustModal from "../../../../HOC/CustModal";
import { postRequest } from "../../CustomHttp";
import ChangePlan from "./ChangePlan";
import { UserInformation } from "./MyProfile";
import { useLocation } from "react-router-dom";
// import ReactPlayer from 'react-player'

function MembershInfo() {
  const location = useLocation() 
  const {userDetail, id} = useContext(UserInformation);  
  const initData = {package_color: 'danger', card_last_digit: '1111', package_status: 'active', person: '1', package_name: ''}

  const [OpenModal, setOpenModal] = useState(false);  
  const [OpenModal1, setOpenModal1] = useState(false);  
  const membership  = userDetail.membership ? userDetail.membership : initData;
  const [AccountStatus, setAccountStatus] = useState(false);

  const changePlanStatus = (e) => {
    const URL = '/update-account-access'
    const params = {id, status: (e ? 1 : 0) }
    const postData = postRequest(URL, params, true);
    if(postData){
      setAccountStatus(e);      
    }
  }
  useEffect(() => {
    console.log("location.state",location);
    if(location.state){
      if(location.state.showPlanPopUp){
        setOpenModal(true)
      }
    }
  },[])
  useEffect(() => {
    setAccountStatus(userDetail.account_access === 1 ? false : true)
    return () => {
      //cleanup
    }
  }, [userDetail])

  return (
    <>
    <CustModal show={OpenModal1} close={() => setOpenModal1(false)} header={'Cancel Subscription'}>
    <div className="row">
                    <div className="col-xs-12 mb-4">
                        <h2 className="text-center mb-5">
                            Are you sure you would like to cancel the plan ?
                        </h2>
                    </div>
                    <div className="col-xs-12 mt-5">
                        <div className="text-center">
                            <button className="btn btn-primary mx-2" onClick={() => setOpenModal1(false)}>Confirm</button>
                            <button className="btn btn-danger mx-2" onClick={() => setOpenModal1(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </CustModal>
      <div class="card card-xxl-stretch mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">Membership</span>
          </h3>
        </div>
        <div class="card-body p-9 pt-0">
          <div class="table-responsive">
            <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
              <tbody>
                <tr>
                  <td class="fw-bolder text-dark text-end customWidthTd">
                    Current Plan
                  </td>
                  <td class="text-normal fs-6 text-dark ">
                    <span class={"badge badge-light-"+(membership.package_color ?? 'red')}>{ membership.package_name } ({ membership.remaining_day_plan ?? 0 })</span>
                  </td>
                </tr>
                <tr>
                  <td class="fw-bolder text-dark text-end customWidthTd">
                    Monthly Users
                  </td>
                  <td class="text-normal fs-6 text-dark ">
                    { membership.person ?? 1 } ({ parseInt(membership.remaining_account) > 0 ? ' ' + membership.remaining_account +`${membership.remaining_account == 1 ? ' seat' : ' seats'} remaining ` : 'Limit Reached' })
                  </td>
                </tr>
                <tr>
                  <td class="fw-bolder text-dark text-end customWidthTd">
                    Monthly Cost
                  </td>
                  <td class="text-normal fs-6 text-dark ">
                    <span>{ membership.total_price }</span>
                  </td>
                </tr>
                <tr>
                  <td class="fw-bolder text-dark text-end customWidthTd">
                    Status
                  </td>
                  <td class="text-normal fs-6 text-dark ">
                    <span class={"badge badge-light-"+(membership.package_color ?? 'red')}>{ membership.package_status }</span>
                  </td>
                </tr>
                {
                  membership.card_last_digit 
                  ?<tr>
                    <td class="fw-bolder text-dark text-end customWidthTd">
                      Card Number
                    </td>
                    <td class="text-normal fs-6 text-dark ">                   
                      **** { membership.card_last_digit }
                    </td>
                  </tr>
                : null
                }
                
                {
                   id
                    ? <tr>
                        <td class="fw-bolder text-dark text-end customWidthTd">
                          Suspend Access
                        </td>
                        <td class="text-normal fs-6 text-dark ">
                          <label class="form-check form-switch form-check-custom form-check-solid">
                                <input class="form-check-input w-30px h-20px" type="checkbox" onChange={(e) => changePlanStatus(e.target.checked)} value={true} name="notifications" checked={AccountStatus} />
                              </label>
                        </td>
                      </tr>
                    : <tr>     
                        <td class="fw-bolder text-dark text-end customWidthTd" style={{"vertical-align":"baseline"}}>
                          {/* Add New Plan */}
                          Subscription
                        </td>            
                        <td class="text-normal fs-6 text-primary fw-bolder">
                          <a onClick={() => setOpenModal(true)}>Change Plan</a>
                          <a className="mt-3 d-block" onClick={() => setOpenModal1(true)}>Cancel Plan</a>                    
                        </td>
                        
                      </tr>
                }                                               
              </tbody>
            </table>
          </div>
        </div>

        {
          id
          ? null
          : <CustModal show={OpenModal} close={() => setOpenModal(false)} header={'Change Plan'}>
              <ChangePlan close={() => setOpenModal(false)} />
            </CustModal>
        }      
      </div>
    </>       
  );
}

export default MembershInfo;
