import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import Loader from 'react-loader-spinner'
import ReactIndex from '../../../HOC/ReactTable/TableIndex'
import { postRequest } from '../CustomHttp'
import { addClient } from '../../../Routes/RouterPage'
import { ClientColumns } from './ClientColumns'
import { useParams } from 'react-router-dom'

export const downloadTokenContext = createContext();

export default function Client() {
    const [clientData, setClientData] = useState(false);
    const [downloadToken, setDownloadToken] = useState('');
    const id = useParams();
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [tableData, setTableData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState({});

    const fetchData = async (params = {}, ID) => {
        setLoading(true);
        params.limit = limit;
        params.page = currentPage+1;
        var myData = await postRequest('/clients', {clinician_id: ID, ...params}, true);
        if(myData) {
            if(myData.status) {
                setClientData(myData.data);                
                setDownloadToken(myData.download_token);
                setTableData({
                    limit: limit,
                    currentPage: currentPage,
                    totalCount: myData.total_count,
                    data: myData.data,
                    search: params.search,
                    from_date: params.from_date,
                    to_date: params.to_date
                });
            }else{
                setClientData([]);
                setTableData({
                    limit: limit,
                    currentPage: currentPage,
                    totalCount: myData.total_count,
                    data: [],
                    search: params.search,
                    from_date: params.from_date,
                    to_date: params.to_date
                });
            }
            setLoading(false);
        }
    }
    // pageIndex, pageSize
    const handlePageChange = (event) => {
        setCurrentPage(event.pageIndex);
        setLimit(event.pageSize);
    };

    const fetchUser = (event) => {
        setSearchParam(event);
        fetchData(event, id.id);
    };

    useEffect(() => {
        fetchData(searchParam, id.id);
    }, [currentPage, limit]);

    useEffect(() => {
        fetchData(searchParam, id.id);        
        return () => {}
    }, [id]);

    return (
        <React.Fragment>
            <div className="content flex-row-fluid tour__step-four--one tour__step-four--three" id="kt_content">                
                {
                    clientData && tableData
                        ? <ReactIndex
                            loader={loading}
                            columnData={ClientColumns}
                            tableDetail={{ name: addClient.name, link: addClient.link+'00',clinician_id:id.id  }} 
                            exportData={{ url: 'client-export', downloadToken }}
                            fetchUser={fetchUser}
                            dateFilter={true}
                            TableData={tableData}
                            handlePageChange={(e) => handlePageChange(e)}
                        />
                        : null
                }
                {/* {loading ? (
                    <center>
                        <Loader
                            type="ThreeDots"
                            color="#017EAD"
                            height={100}
                            width={100}
                            timeout={300000} />
                    </center>
                    ) : null
                }                   */}
            </div>                                 
        </React.Fragment>
    )
}
