import React, { useContext, useState } from 'react'
import Loader from 'react-loader-spinner';
import PaymentLoader from '../../auth/PaymentLoader';
import { postRequest } from '../../CustomHttp';
import { UserInformation } from './MyProfile';
import PlanCost from './PlanCost';
import Toastify from '../../../../component/toast';
import { toast } from 'react-toastify';

function ChangePlan(props) {
    // let nounce = null;
    const [nounce, setNounce] = useState(null)
    const [planId, setplanId] = useState(false)
    // let planId = false;
    // let planCost = false;
    const [planCost, setPlanCost] = useState(false)
    const {userDetail, setUserDetail} = useContext(UserInformation);
    console.log("userDetail",userDetail)
    //
    const getNonce = (e) => {
        // nounce = e;    
        setNounce(e)    
        if(e && planId){
            chnagePlan(e);
        }

    }
    
    const getPlanIdAndCost = (id, cost) => {
        console.log(id);
        console.log(cost);
        // planId = id;
        setplanId(id)
        // planCost = cost;  
        setPlanCost(cost);      
    }

    //
    const chnagePlan = async (e) => {
        const URL = '/change-plan';
        const postData = { package: planId, nonce: e };
        const uploadData = await postRequest(URL, postData, true);
        if(uploadData){
            console.log(uploadData);
            if(uploadData.status){
                setUserDetail({...userDetail, membership: uploadData.data});
                props.close();
            } else {
                toast.error(uploadData.message)
            }

        }
    }

    return (
        <>
        <Toastify/>
        <div>
            {/* <div className="text-center">
                <Loader type="ThreeDots" color="#017EAD" height={100} width={100} timeout={3500}/>                    
            </div> */}
            <PlanCost shareIdAndCost={getPlanIdAndCost} minVal={userDetail.membership.person ?? 1} /> 
            {/* <div class="text-center">
                <button type="button" id="kt_sign_up_submit" class="btn btn-lg btn-primary" onClick={chnagePlan}>Update</button>
            </div> */}
            {
                planCost ? <PaymentLoader getNonce={getNonce} planCost={planCost} 
                userData={{
                    billingContact: {
                      givenName: userDetail.first_name,
                      familyName: userDetail.last_name,
                      email: userDetail.email,
                      phone: userDetail.phone_number ?? "",
                      addressLines: [userDetail.phy_address??""],
                      city: userDetail.phy_city_name??"",
                      state: userDetail.phy_state_name??"",
                      countryCode: 'US',
                    },
                    amount: planCost,
                    currencyCode: 'USD',
                    intent: 'CHARGE',
                  }}
                /> : null
            }
            
        </div>
        </>
    )
}

export default ChangePlan
