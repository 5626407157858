import React from 'react'
import { useParams } from 'react-router-dom'
import MyProfile from '../other/userProfile/MyProfile'

export default function ViewClinician() {
  const userId = useParams();
    return (
        <React.Fragment>
          <MyProfile userId={userId.id} from="clinician" />                               
      </React.Fragment>
    )
}
